function showmorebutton_articles() {
    if ($('.news-ncategory .pagination .pagi li:last-child a').hasClass('has_link')) {
		var more_button = $('.news-ncategory .more_button_text').text();
        $('.news-ncategory .more_button_container').html('<button onclick="showmore_articles()" id="showmore" class="reset_button button" id="more_products">' + more_button + '</button>');

    }
}



function showmore_articles() {

    var $next = $('.news-ncategory .pagination .pagi li:last-child a.has_link');
	
	
    if ($next.length == 0) {
        return;
    }
    $.get($next.attr('href'), function (data) {
        $data = $(data);		
        var $container = $('.blog-articles-list');
		$container.append($data.find('.blog-articles-list .article'));

        $('.news-ncategory .bottom-wrapper').remove();
        $(".blog-articles-container").append($(data).find('.bottom-wrapper'));

        /*setTimeout(function(){
            add_class();
            p_array();
        }, 500);*/
		

    }, "html");
    return false;
}
