function get_popup_form(sign_id) {
	//console.log('get_popup_form_click');
	$.ajax({
		url: 'index.php?route=sendmess/send_message/get_popup_form&sign_id='+sign_id,
		type: 'post',
		success: function(response) {
			// Add response in Modal body
			if(sign_id == 5){
				$('#request-sale-modal').html(response);
				$('#request-sale-modal').modal();
			}else {
				$('#modal_container_prices').html(response);
				$('#modal_container_prices').modal();
				$("#modal_container_prices input[type='tel']").mask("+7 999 999 99 99");
			}
			send_popup_form();
		}
	});
};

function send_popup_form(){
	$('#modal_container_prices button#submit_button').click(function () {
		console.log('send_popup_form');
		$.ajax({
			type: 'post',
			url:  'index.php?route=sendmess/send_message/send_popup_form',
			dataType: 'json',
			data: $('#modal_container_prices .popup_form').serialize(),

			success: function (json) {
				if (json['error']) {
					$("#modal_container_prices .simple_input_container").removeClass('has-error');
					$('#modal_container_prices div.error').remove();
					if (json['error_name']){
						$('#modal_container_prices input[name="name"]').after('<div class="error">' + json['error_name'] + '</div>');
						$('#modal_container_prices input[name="name"]').parent().addClass('has-error');
					}
					if (json['error_phone']){
						$('#modal_container_prices input[name="phone"]').after('<div class="error">' + json['error_phone'] + '</div>');
						$('#modal_container_prices input[name="phone"]').parent().addClass('has-error');
					}
					if (json['error_email']){
						$('#modal_container_prices input[name="email"]').after('<div class="error">' + json['error_email'] + '</div>');
						$('#modal_container_prices input[name="email"]').parent().addClass('has-error');
					}
					if (json['error_agree']){
						$('#modal_container_prices input[name="agree"]').parent().find('label').after('<div class="error">' + json['error_agree'] + '</div>');
						$('#modal_container_prices input[name="agree"]').parent().addClass('has-error');
					}
				}

				if (json['success']) {
					$('#modal_container_prices').modal('hide');
					setTimeout(function(){
						$('#modal_container_prices > div').remove();
					}, 1000);
					$('#modal_container_success .modal-header .title').html(json['success_title']);
					$('#modal_container_success .modal-body').html(json['success']);
					$('#modal_container_success').modal();
				}
			}


		});

	});
};

function send_sale_form(){
	$('#request-sale-modal button#submit_button').click(function () {
		$.ajax({
			type: 'post',
			url:  'index.php?route=sendmess/send_message/send_sale_form',
			dataType: 'json',
			data: $('#request-sale-modal .popup_form').serialize(),

			success: function (json) {
				if (json['error']) {
					$("#request-sale-modal .simple_input_container").removeClass('has-error');
					$('#request-sale-modal div.error').remove();

					if (json['error_email']){
						$('#request-sale-modal input[name="email"]').after('<div class="error">' + json['error_email'] + '</div>');
						$('#request-sale-modal input[name="email"]').parent().addClass('has-error');
					}
					if (json['error_agree']){
						$('#request-sale-modal input[name="agree"]').parent().find('label').after('<div class="error">' + json['error_agree'] + '</div>');
						$('#request-sale-modal input[name="agree"]').parent().addClass('has-error');
					}
				}

				if (json['success']) {
					document.cookie = 'popupbanner=true; path=/;';
					$('#request-sale-modal').modal('hide');
					setTimeout(function(){
						$('#request-sale-modal > div').remove();
					}, 1000);
					$('#modal_container_success .modal-header .title').html(json['success_title']);
					$('#modal_container_success .modal-body').html(json['success']);
					$('#modal_container_success').modal();
				}
			}


		});

	});
};

/*******************/


	$('#request-sale-modal button#submit_button').click(function () {
		$.ajax({
			type: 'post',
			url:  'index.php?route=sendmess/send_message/send_sale_form',
			dataType: 'json',
			data: $('#request-sale-modal .popup_form').serialize(),

			success: function (json) {
				if (json['error']) {
					$("#request-sale-modal .simple_input_container").removeClass('has-error');
					$('#request-sale-modal div.error').remove();

					if (json['error_email']){
						$('#request-sale-modal input[name="email"]').after('<div class="error">' + json['error_email'] + '</div>');
						$('#request-sale-modal input[name="email"]').parent().addClass('has-error');
					}
					if (json['error_agree']){
						$('#request-sale-modal input[name="agree"]').parent().find('label').after('<div class="error">' + json['error_agree'] + '</div>');
						$('#request-sale-modal input[name="agree"]').parent().addClass('has-error');
					}
				}

				if (json['success']) {
					document.cookie = 'popupbanner=true; path=/;';
					$('#request-sale-modal').modal('hide');
					setTimeout(function(){
						$('#request-sale-modal > div').remove();
					}, 1000);
					$('#modal_container_success .modal-header .title').html(json['success_title']);
					$('#modal_container_success .modal-body').html(json['success']);
					$('#modal_container_success').modal();
				}
			}


		});

	});



/********************************************************************************************************************/

function get_popup_purchase(product_id) {
	$.ajax({
		url: 'index.php?route=module/popup_purchase&product_id='+product_id,
		type: 'post',
		success: function(response) {
			// Add response in Modal body
			$('#modal_container_fastorder .modal-body').html(response);
			$('#modal_container_fastorder').modal();
			$("#popup_purchase_telephone").mask("+7 999 999 99 99");
			popup_purchase_buy();
		}
	});
};

function getURLVar(key) {
	var value = [];

	var query = String(document.location).split('?');

	if (query[1]) {
		var part = query[1].split('&');

		for (i = 0; i < part.length; i++) {
			var data = part[i].split('=');

			if (data[0] && data[1]) {
				value[data[0]] = data[1];
			}
		}

		if (value[key]) {
			return value[key];
		} else {
			return '';
		}
	}
}

function loacation() {
	location.reload();
}

/***popup_purchase functions******/

function validate( input ) {
	input.value = input.value.replace( /[^\d,]/g, '' );
}

function popup_purchase_buy(){
	$('#popup-checkout-button').click(function() {
		$.ajax({
			type: 'post',
			url:  'index.php?route=module/popup_purchase/make_order',
			dataType: 'json',
			data: $('#purchase-form').serialize(),
			success: function(json) {

				if (json['error']) {
					$('#purchase-form .simple_input_container').removeClass('has-error');
					$('#purchase-form .error').remove();

					if (json['error']['field']) {
						//masked('#popup-purchase-wrapper', false);
						$('#popup-purchase-wrapper .simple_input_container').removeClass('has-error');
						$('#popup-purchase-wrapper div.error').remove();
						$.each(json['error']['field'], function(i, val) {
							$('#popup-purchase-wrapper [name="' + i + '"]').parent().addClass('has-error');
							$('#popup-purchase-wrapper [name="' + i + '"]').after('<div class="error">' + val + '</div>');
						});
					}

					if (json['error']['option']) {
						for (i in json['error']['option']) {
							//masked('#popup-purchase-wrapper', false);
							$('#popup-purchase-wrapper .required .error').remove();
							var element = $('#popup-purchase-wrapper #input-option' + i.replace('_', '-'));
							element.after('<div class="error">' + json['error']['option'][i] + '</div>');
						}
					}

					if (json['error']['recurring']) {
						$('#popup-purchase-wrapper select[name=\'recurring_id\']').after('<div class="error">' + json['error']['recurring'] + '</div>');
					}

					if (json['error']['error_agree']){
						$('#popup-purchase-wrapper input[name="agree"]').parent().find('label').after('<div class="error">' + json['error']['error_agree'] + '</div>');
						$('#popup-purchase-wrapper input[name="agree"]').parent().addClass('has-error');
					}

				} else {
					$('#popup-purchase-wrapper .simple_input_container').removeClass('has-error');

					if (json['output']) {
						$('#modal_container_fastorder').modal('hide');
						$('#purchase-form input').val('');
						$('#modal_container_success .modal-header .title').text(json['output_title']);
						$('#modal_container_success .modal-body').text(json['output']);

						setTimeout(function () {
							$('#modal_container_success').modal();
						}, 700)

					}
				}
			}
		});
	});
}

function replaceUrlParam(url, paramName, paramValue){
	if (paramValue == null) {
		paramValue = '';
	}
	var pattern = new RegExp('\\b('+paramName+'=).*?(&|#|$)');
	if (url.search(pattern)>=0) {
		return url.replace(pattern,'$1' + paramValue + '$2');
	}
	url = url.replace(/[?#]$/,'');
	return url + (url.indexOf('?')>0 ? '&' : '?') + paramName + '=' + paramValue;
}

function customSelectInit(){
	//console.log('customSelectInit');
	$(".custom-select select").each(function() {
		if(!$(this).hasClass('is-customized')) {
			$(this).addClass('is-customized');
			var sb = new SelectBox({
				selectbox: $(this),
				customScrollbar: true,
				height: 300,
				changeCallback: function() {
					//alert("I was changed!");
				}

			});
		};
	});
}

function onlyDigit(){
	$("input.qty").keypress(function(e) { //if the letter is not digit then display error and don't type anything
		if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
			return false
		}
	});
}

function updatePopupCart(key , quantity) {
	$.ajax({
		url: 'index.php?route=checkout/cart/edit',
		type: 'post',
		data: 'key=' + key + '&quantity=' + (typeof (quantity) != 'undefined' ? quantity : 1),
		dataType: 'json',
		beforeSend: function () {
			//$('#cart > button').button('loading');
		},
		complete: function () {
			$('.cart > button').button('reset');
		},
		success: function (json) {
			// Need to set timeout otherwise it wont update the total
			setTimeout(function () {
				//$("#modal_container_addcart #cart_popup_product_list > ul" ).load( "index.php?route=common/cart/info ul li");
			}, 2000);
		},
		error: function (xhr, ajaxOptions, thrownError) {
			alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
		}
	});
}

function getCookiePopup(c_name) {
	var search = c_name + "="
	var returnvalue = "";
	if (document.cookie.length > 0) {
		offset = document.cookie.indexOf(search)
		if (offset != -1) {
			offset += search.length
			end = document.cookie.indexOf(";", offset);
			if (end == -1) end = document.cookie.length;
			returnvalue=unescape(document.cookie.substring(offset, end))
		}
	}
	return returnvalue;
}
/***************************************************************************************/
$(document).ready(function() {


	$('#partners_form button.submit_button').click(function () {
		console.log('partners_form');
		$.ajax({
			type: 'post',
			url:  'index.php?route=information/contact/sendPartner',
			dataType: 'json',
			data: $('#partners_form').serialize(),

			success: function (json) {
				if (json['error']) {
					$("#partners_form .simple_input_container").removeClass('has-error');
					$('#partners_form div.error').remove();
					if (json['error_name']){
						$('#partners_form input[name="name"]').after('<div class="error">' + json['error_name'] + '</div>');
						$('#partners_form input[name="name"]').parent().addClass('has-error');
					}
					if (json['error_phone']){
						$('#partners_form input[name="phone"]').after('<div class="error">' + json['error_phone'] + '</div>');
						$('#partners_form input[name="phone"]').parent().addClass('has-error');
					}
					if (json['error_email']){
						$('#partners_form input[name="email"]').after('<div class="error">' + json['error_email'] + '</div>');
						$('#partners_form input[name="email"]').parent().addClass('has-error');
					}
					if (json['error_message']){
						$('#partners_form textarea[name="message"]').after('<div class="error">' + json['error_message'] + '</div>');
						$('#partners_form textarea[name="message"]').parent().addClass('has-error');
					}

				}

				if (json['success']) {
					$("#partners_form .simple_input_container").removeClass('has-error');
					$('#partners_form div.error').remove();
					$('#partners_form input').val('');
					$('#partners_form textarea').val('');

					$('#modal_container_success .modal-header .title').html(json['success_title']);
					$('#modal_container_success .modal-body').html(json['success']);
					$('#modal_container_success').modal();
				}
			}


		});

	});



	/*   to poducts after sort  */
	if ($('body').hasClass('category-page')){
		console.log('category-page');
		var url_string = window.location;
		var url = new URL(url_string);
		var sort = url.searchParams.get("sort");

		if (sort){
			setTimeout(function () {
				console.log('is_sort');

				var anchor = '#content';
				var to = $(anchor).offset().top - 90;

				if(Modernizr.mq('(max-width: 767px)')) {
					var to = $(anchor).offset().top - 80;
				}

				/*if(Modernizr.mq('(min-width: 768px)')) {
					console.log('tablet');
					var to = $(anchor).offset().top - 150;
				}

				if(Modernizr.mq('(min-width:991px)')) {
					console.log('no_tablet');
					var to = $(anchor).offset().top - 100;
				}*/

				$('html, body').stop().animate({
					scrollTop: to	}, 1000);
			}, 1000)
		}
	}


	$("input[type='tel']").mask("+7 999 999 99 99");

	/********SALE FORM *****************/
	var showPopup = getCookiePopup('popupbanner');

	if (showPopup == '') {
		setTimeout(function () {
			get_popup_form(5);
			setTimeout(function () {
				send_sale_form();
			}, 500)
		}, 10000)
		$('body').on('click', '#request-sale-modal button.close', function (e) {
			//document.cookie = 'popupbanner=bigTimeout; path=/;';
			document.cookie = 'popupbanner=true; path=/;';
		});
	}else if(showPopup == 'bigTimeout'){
		setTimeout(function () {
			get_popup_form(5);
			setTimeout(function () {
				send_sale_form();
			}, 500)
		}, 180000)
	}
	/****** Page downloads ************/
	$('body').on('mouseup', '.downloads__heading', function () {
		$(this).toggleClass('active').next().stop().slideToggle()
	});

	$('body').on('submit', '.file-search', function (e) {
		e.preventDefault();
	});
	$('body').on('keyup', '.file-search input', function (e) {
		e.preventDefault();
		var keyword = $(this).val();
		keyword = keyword.toLowerCase();
		keyword = keyword.split(' ');

		for (var i = 0; i < keyword.length; i++) {

			$('.downloads__item').each(function () {
				var str = $(this).find('.downloads__name').text().toLowerCase();
				if (str.indexOf(keyword[i]) !== -1) {
					$(this).removeClass('hidden')
				} else {
					$(this).addClass('hidden')
				}
			});
			$('.downloads__items').each(function () {
				if ($(this).find('.downloads__item:not(.hidden)').length > 0) {
					$(this).prev().removeClass('hidden')
				} else {
					$(this).prev().addClass('hidden')
				}
			});
		}

		if ($('.downloads__heading:not(.hidden)').length < 1) {
			$( '.non-result' ).show();
		} else {
			$( '.non-result' ).hide();
		}
	});
	/**********Callback***********************/
	$('button.callback').click(function(event){
		console.log('#button#callback');
		$('#request-callback-modal').modal();
	});
	$("#input-phone-callback").mask("+7 999 999 99 99");
	$('#request_callback-btn').on('click', function () {
		$.ajax({
			url: 'index.php?route=sendmess/send_message/send',
			type: 'post',
			dataType: 'json',
			data:  $("#request_callback-form").serialize(),
			beforeSend: function () {
			},
			complete: function () {
			},
			success: function (json) {
				if (json['error']) {
					$('#request_callback-form label.error').remove();
					$("#request_callback-form .simple_input_container").removeClass('has-error');
					if (json['error_name']){
						$('#request_callback-form input[name="name"]').after('<label class="error">' + json['error_name'] + '</label>');
						$('#request_callback-form input[name="name"]').parent().addClass('has-error');
					}
					if (json['error_phone']){
						$('#request_callback-form input[name="phone"]').after('<label class="error">' + json['error_phone'] + '</label>');
						$('#request_callback-form input[name="phone"]').parent().addClass('has-error');
					}

					if (json['error_agree']){
						$('#request_callback-form input[name="agree"]').parent().find('label').after('<div class="error">' + json['error_agree'] + '</div>');
						$('#request_callback-form input[name="agree"]').parent().addClass('has-error');
					}

				}

				if (json['success']) {
					$("#request_callback-form .simple_input_container").removeClass('has-error');
					$('#request_callback-form label.error').remove();
					$('#request_callback-form input[name=\'name\']').val('');
					$('#request_callback-form input[name=\'phone\']').val('');

					$('#request-callback-modal').modal('hide');
					$('#modal_container_success .modal-header .title').html(json['success_title']);
					$('#modal_container_success .modal-body').html(json['success']);
					$('#modal_container_success').modal();
				}
			}
		});
	});


	/********Price_form***********************/

	$('#price_form button[type="button"]').click(function() {

		$('#price_form label.error').remove();

		$.ajax({
			url: 'index.php?route=sendmess/send_message/send_pricelist_form',
			type: 'post',
			data: $('#price_form input[type=\'hidden\'], #price_form input[type=\'text\'], #price_form input[type=\'tel\'], #price_form input[type=\'email\'], #price_form textarea'),
			dataType: 'json',
			beforeSend: function() {
			},
			complete: function() {
			},
			success: function(json) {
				$('#price_form .line .simple_input_container').removeClass('has-error');
				if (json['error']){
					if (json['error_name']) {

						$('#price_form input[name=\'name\']').parent().addClass('has-error');
						$('#price_form input[name=\'name\']').focus();
						$('#price_form input[name=\'name\']').parent().append('<label class="error">' + json['error_name'] + '</label>');
					}
					if (json['error_email']) {
						$('#price_form input[name=\'email\']').parent().addClass('has-error');
						$('#price_form input[name=\'email\']').focus();
						$('#price_form  input[name=\'email\']').parent().append('<label class="error">' + json['error_email'] + '</label>');
					}
					if (json['error_phone']) {
						$('#price_form input[name=\'phone\']').parent().addClass('has-error');
						$('#price_form input[name=\'phone\']').focus();
						$('#price_form input[name=\'phone\']').parent().append('<label class="error">' + json['error_phone'] + '</label>');
					}
					/*if (json['errors']['error_message']) {
						$('#price_form textarea[name=\'message\']').parent().addClass('has-error');
						$('#price_form textarea[name=\'message\']').focus();
						$('#price_form textarea[name=\'message\']').parent().append('<label class="error">' + json['errors']['error_message'] + '</label>');
					}*/
					if (json['error_company']) {
						$('#price_form input[name=\'company\']').parent().addClass('has-error');
						$('#price_form input[name=\'company\']').focus();
						$('#price_form input[name=\'company\']').parent().append('<label class="error">' + json['error_company'] + '</label>');
					}
				}

				if(json['success']) {
					//alert('Письмо отправлено');
					$('#price_form input[name=\'name\']').val('');
					$('#price_form input[name=\'company\']').val('');
					$('#price_form input[name=\'email\']').val('');
					$('#price_form input[name=\'phone\']').val('');
					$('#price_form textarea[name=\'message\']').val('');


					$('#modal_container_success .modal-header .title').html(json['success_title']);
					$('#modal_container_success .modal-body').html(json['success']);
					$('#modal_container_success').modal();
				}
			}
		});
	});

	/*****************************/


	/********Question_form*********************/
	$(".input-telephone").mask("+7 999 999 99 99");

	$('#question_form .custom_input_file label').on('click', function() {
		$('#form-upload-file').remove();

		$('body').prepend('<form enctype="multipart/form-data" id="form-upload-file" style="display: none;"><input type="file" name="file" /></form>');

		$('#form-upload-file input[name=\'file\']').trigger('click');

		if (typeof timer != 'undefined') {
			clearInterval(timer);
		}

		timer = setInterval(function () {
			if ($('#form-upload-file input[name=\'file\']').val() != '') {
				//console.log('start_upload');
				clearInterval(timer);

				$.ajax({
					url: 'index.php?route=common/questionform/uploadFile',
					type: 'post',
					dataType: 'json',
					data: new FormData($('#form-upload-file')[0]),
					cache: false,
					contentType: false,
					processData: false,
					beforeSend: function () {
					},
					complete: function () {
					},
					success: function (json) {
						if (json['error']) {
							//alert('error');
							$("#file_err").children("div#err_backend").remove();
							$('#custFile').attr('value', '');
							$('#custFileDir').attr('value', '');
							$('#file_err').append('<div class="error" id="err_backend">' + json['error'] + '</div>');
						} else if (json['success']) {
							//alert('success');
							$('#question_form .custom_input_file label').text(json['success']['fileName']);
							$("#file_err").children("div#err_backend").remove();
							$('#custFile').attr('value', json['success']['fileName']);
							$('#custFileDir').attr('value', json['success']['fileDir']);
						}

					},
					error: function (xhr, ajaxOptions, thrownError) {
						alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
					},
				});
			}
		}, 500);

	});



	$('#question_form button[type="button"]').click(function() {



		$('#question_form label.error').remove();

		$.ajax({
			url: 'index.php?route=common/questionform/sendquestionform',
			type: 'post',
			data: $('#question_form input[type=\'hidden\'], #question_form input[type=\'text\'], #question_form input[type=\'tel\'], #question_form input[type=\'email\'], #question_form input[type=\'file\'], #question_form textarea'),
			dataType: 'json',
			beforeSend: function() {

			},
			complete: function() {

			},
			success: function(json) {


				$('#question_form .line .simple_input_container').removeClass('has-error');
				if (json['errors']){
					if (json['errors']['error_name']) {
						$('#question_form input[name=\'name\']').parent().addClass('has-error');
						$('#question_form input[name=\'name\']').focus();
						$('#question_form input[name=\'name\']').parent().append('<label class="error">' + json['errors']['error_name'] + '</label>');
					}
					if (json['errors']['error_email']) {
						$('#question_form input[name=\'email\']').parent().addClass('has-error');
						$('#question_form input[name=\'email\']').focus();
						$('#question_form input[name=\'email\']').parent().append('<label class="error">' + json['errors']['error_email'] + '</label>');
					}
					if (json['errors']['error_phone']) {
						$('#question_form input[name=\'phone\']').parent().addClass('has-error');
						$('#question_form input[name=\'phone\']').focus();
						$('#question_form input[name=\'phone\']').parent().append('<label class="error">' + json['errors']['error_phone'] + '</label>');
					}
					if (json['errors']['error_message']) {
						$('#question_form textarea[name=\'message\']').parent().addClass('has-error');
						$('#question_form textarea[name=\'message\']').focus();
						$('#question_form textarea[name=\'message\']').parent().append('<label class="error">' + json['errors']['error_message'] + '</label>');
					}
				}

				if(json['success']) {
					//alert('Письмо отправлено');
					$('#question_form .custom_input_file label').html('<span class="ani icon icon-attach"></span><span class="ani text">'+json['text_attach']+'</span>');
					$('#question_form input[name=\'name\']').val('');
					$('#question_form input[name=\'email\']').val('');
					$('#question_form input[name=\'phone\']').val('');
					$('#question_form textarea[name=\'message\']').val('');


					$('#modal_container_success .modal-header .title').html(json['success_title']);
					$('#modal_container_success .modal-body').html(json['success_text']);
					$('#modal_container_success').modal();
				}
			}
		});
	});
	/**********NewsMailchimpSubscribe******************/


	$('.newsletter_mailchimp button').click(function () {
		$.ajax({
			type: 'post',
			url:  'index.php?route=sendmess/send_message/newsletter_subscribe',
			dataType: 'json',
			data: $('.newsletter_mailchimp').serialize(),

			success: function (json) {
				if (json['error']) {
					$(".newsletter_mailchimp .input_container").removeClass('has-error');
					$('.newsletter_mailchimp div.error').remove();

					if (json['error_email']){
						$('.newsletter_mailchimp input[name="email"]').after('<div class="error">' + json['error_email'] + '</div>');
						$('.newsletter_mailchimp input[name="email"]').parent().addClass('has-error');
					}
				}

				if (json['success']) {
					$('.newsletter_mailchimp input[name="email"]').val('');
					$(".newsletter_mailchimp .input_container").removeClass('has-error');
					$('.newsletter_mailchimp div.error').remove();

					$('#modal_container_success .modal-header .title').html(json['success_title']);
					$('#modal_container_success .modal-body').html(json['success']);
					$('#modal_container_success').modal();
				}
			}


		});

	});



	/*****************************/
	if (!("ontouchstart" in document.documentElement)) {
		document.documentElement.className += " no-touch";
	}else{
		document.documentElement.className += " is-touch";
	}
	/**********Open/close hidden menu********************************/
	$('header .button_hamburger').click(function(){
		$(this).toggleClass('active');
		$('body').toggleClass('overflow_hidden');
		$('.hidden_menu').toggleClass('open');
	})
	/****************************/
	customSelectInit();
	onlyDigit();
	/****************Main slideshow*************************************************************************/
	if($('#main_slideshow').length) {
		$('#main_slideshow').slick({
			infinite: true,
			arrows: true,
			prevArrow: '<span class="icon slick-arrow-left icon-arrow"></span>',
			nextArrow: '<span class="icon slick-arrow-right icon-arrow"></span>',
			dots: true,
			autoplay: true,
			autoplaySpeed: 4000,
			//appendDots: $('.mainslider_dots_container .container'),
		});
	};

	/******************Populars slideshow************************************************/
	if(Modernizr.mq('(max-width: 767px)')) {
		if($('.common-home .box_module.category_module').length) {
			$('.common-home .box_module.category_module').slick({
				infinite: true,
				arrows: false,
				dots: true,
				autoplay: true,
				autoplaySpeed: 4000,
				slidesToShow: 1,
				slidesToScroll: 1
			});
		};
	};

	/*************************/
	$('.mfilter-box .box-heading .add_filters_reset').click(function(){
		$('.mfilter-box .mfilter-button-reset').click();
	});

	/********************* Mob open filter **************************/

	$("body").on("click", ".action_buttons .open_filter", function() {
		$('body').addClass('overflow_hidden');
		$('.mfilter-free-container').addClass('open');
	});


	$("body").on("click", ".mfilter-box .box-heading .icon-close2", function() {
		$('body').removeClass('overflow_hidden');
		$('.mfilter-free-container').removeClass('open');
	});

	/****************************************************************/
	if(Modernizr.mq('(max-width: 767px)')) {

	}
	/******** Category Showmore ***************/
	if($('.products_row').length){
		showmorebutton();
	}
	/***************************************************************************************/
	var menu = $('#top_menu_container');

	menu.find('li a').each(function () {
		if ($(location).attr('href').indexOf($(this).attr('href')) >= 0){
			$(this).addClass('active');
			return;
		}
	});
	/***************** Search *************************************************************/

	$('.search input[name=\'search\']').parent().find('button').on('click', function () {
		url = $('base').attr('href') + 'index.php?route=product/search';

		var value = $('.search input[name=\'search\']').val();

		if (value) {
			url += '&search=' + encodeURIComponent(value);
		}

		location = url;
	});

	$('.search input[name=\'search\']').on('keydown', function (e) {
        if (e.keyCode == 13) {
            $('header input[name=\'search\']').parent().find('button').trigger('click');
        }
    });

	/***************Search page*************************************************************************************/

	if($('.search_page').length) {
		$('#button-search').bind('click', function () {
			url = 'index.php?route=product/search';

			var search = $('#content input[name=\'search\']').prop('value');

			if (search) {
				url += '&search=' + encodeURIComponent(search);
			}

			var category_id = $('#content select[name=\'category_id\']').prop('value');

			if (category_id > 0) {
				url += '&category_id=' + encodeURIComponent(category_id);
			}

			var sub_category = $('#content input[name=\'sub_category\']:checked').prop('value');

			if (sub_category) {
				url += '&sub_category=true';
			}

			var filter_description = $('#content input[name=\'description\']:checked').prop('value');

			if (filter_description) {
				url += '&description=true';
			}

			location = url;
		});

		$('#content input[name=\'search\']').bind('keydown', function (e) {
			if (e.keyCode == 13) {
				$('#button-search').trigger('click');
			}
		});

		$('select[name=\'category_id\']').on('change', function () {
			if (this.value == '0') {
				$('input[name=\'sub_category\']').prop('disabled', true);
			} else {
				$('input[name=\'sub_category\']').prop('disabled', false);
			}
		});

		$('select[name=\'category_id\']').trigger('change');
	}


});
/***************************************************************************************/
if ($('.product-big-image.gallery').length){
	setTimeout(function(){
		$('.product-big-image.gallery').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			fade: true,
			prevArrow: '<span class="hidden ani icon icon-arrow_left"></span>',
			nextArrow: '<span class="hidden ani icon icon-arrow_right"></span>',
			asNavFor: '.image-additional',
			arrows: true,
		});

		let big_image_conainer_height = $('.product_top_block .image_col').height();
		$('.add_images_col').height(big_image_conainer_height);

		$('.image-additional').slick({
			autoplay: true,
			autoplaySpeed: 4000,
			vertical: true,
			slidesToShow: 5,
			slidesToScroll: 1,
			asNavFor: '.product-big-image',
			arrows: false,
			dots: false,
			centerMode: false,
			focusOnSelect: true,
			responsive: [
				{
					breakpoint: 1025,
					settings: {
						slidesToShow: 5,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1
					}
				}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			]

		});
	}, 200);
	$('.product-big-image.gallery a').magnificPopup({
		type:'image',
		gallery: {
			enabled: true
		}
	});

};


if ($('.product-big-image').length){
	$('.product-big-image a.popup').magnificPopup({
		type:'image',
		gallery: {
			enabled: false
		}
	});

	$("body").on("click", ".mp", function() {
		$(this).prev().click();
	})
}
/******************Anchor to full description*********************************/

$('#product a.to_desc').click(function(event){
	event.preventDefault();

	var anchor = '#tab-description';
	var to = $(anchor).offset().top - 160;

	if(Modernizr.mq('(max-width: 767px)')) {
		var to = $(anchor).offset().top - 145;
	}
	$('html, body').stop().animate({
		scrollTop: to	}, 1000);
});
/******************Related slideshow************************************************/
if($('.related_module').length) {
	$('.related_module .related_slider').slick({
		infinite: true,
		arrows: true,
		dots: false,
		autoplay: true,
		autoplaySpeed: 4000,
		slidesToShow: 4,
		slidesToScroll: 1,
		prevArrow: '<span class="ani icon icon-arrow_left"></span>',
		nextArrow: '<span class="ani icon icon-arrow_right"></span>',
		//mobileFirst: true, //optional, to be used only if your page is mobile first
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4,
				}
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1
				}
			}

		]
	});
};
/****************** Compare slider **********************************/
if($('.compare_slider').length) {

	var $slickElement = $('.compare_slider');

	$slickElement.on('init', function (event, slick, currentSlide, nextSlide) {
		//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
		var maxHeight = 0;
		var highestElement = {};
		$('.compare_slider .product .product_top_block').each(function() {
			var thisHeight = $(this).outerHeight();
			if (thisHeight>maxHeight) {
				maxHeight = thisHeight;
				highestElement = $(this);
			}
		});
		$('.compare_slider .product .product_top_block').css('min-height', maxHeight);
	});


	$('.compare_slider').slick({
		infinite: true,
		arrows: true,
		dots: false,
		autoplay: true,
		autoplaySpeed: 400000,
		slidesToShow: 4,
		slidesToScroll: 1,
		prevArrow: '<span class="ani icon icon-arrow_left"></span>',
		nextArrow: '<span class="ani icon icon-arrow_right"></span>',
		appendArrows: $('.arrows_container'),
		//mobileFirst: true, //optional, to be used only if your page is mobile first
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1
				}
			}

		]
	});
};
/************ Change product - qty **************************************/
$(document).on('click', '.qty_wrap .bnt_plus', function () {
	console.log('plus');
	var num = parseInt($(this).prev().val());
	var available_qty = parseInt($(this).prev().data('qty'));
	var min = parseInt($(this).prev().data('min'));
	$(this).prev().val(num + min);

	if($(this).hasClass('popup_cart_btn')){

		key = $(this).prev().data('id');
		quantity = $(this).prev().val();
		product_id = $(this).prev().data('product-id');

		cart.update(key,quantity,product_id);

	}

	if($(this).hasClass('in_cart')) {
		setTimeout(function () {
			$('#refresh_card').click();
		}, 1000)
	}

});


$(document).on('click', '.qty_wrap .bnt_minus', function () {
	console.log('minus');
	if ($(this).next().val() > $(this).next().data('min')) {
		var num = parseInt($(this).next().val());
		$(this).next().val(num - parseInt($(this).next().data('min')))

		if($(this).hasClass('popup_cart_btn')){
			key = $(this).next().data('id');
			quantity = $(this).next().val();
			product_id = $(this).next().data('product-id');
			cart.update(key,quantity,product_id);
		}

		if($(this).hasClass('in_cart')) {
			setTimeout(function () {
				$('#refresh_card').click();
			}, 1000)
		}

	}


});


/*****************************************************************/
$(document).on('click', '.product-layout .buy_button', function () {

	var button =$(this);
	var product_id = parseInt($(this).prev().data('id'));

	var qty = 1;
	cart.add(product_id, qty);

	button.addClass('added');

	setTimeout(function () {
		button.removeClass('added');
	}, 2000);
});


$(document).on('click', '#product .buy_button', function () {

	$.ajax({
		url: 'index.php?route=checkout/cart/add',
		type: 'post',
		data: $('#product input[type=\'text\'], #product input[type=\'hidden\'], #product input[type=\'radio\']:checked, #product input[type=\'checkbox\']:checked, #product select, #product textarea'),
		dataType: 'json',
		beforeSend: function() {
			//$('#button-cart').button('loading');
		},
		complete: function() {
			//$('#button-cart').button('reset');
		},
		success: function(json) {
			$('.alert, .text-danger').remove();
			$('.form-group').removeClass('has-error');

			if (json['error']) {
				if (json['error']['option']) {
					for (i in json['error']['option']) {
						var element = $('#input-option' + i.replace('_', '-'));
						if (element.parent().hasClass('input-group')) {
							element.parent().after('<div class="text-danger">' + json['error']['option'][i] + '</div>');
						} else {
							element.after('<div class="text-danger">' + json['error']['option'][i] + '</div>');
						}
					}
				}

				if (json['error']['recurring']) {
					$('select[name=\'recurring_id\']').after('<div class="text-danger">' + json['error']['recurring'] + '</div>');
				}

				// Highlight any found errors
				$('.text-danger').parent().addClass('has-error');
			}

			if (json['success']) {
				setTimeout(function() {
					setTimeout(function () {
						$('.cart > button').html('<i class="icon icon-cart ani"></i><span class="cart-total">' + json['total'] + '</span>');
						$( "#modal_container_addcart #cart_popup_product_list > ul" ).load( "index.php?route=common/cart/info ul li", function() {
							$('#modal_container_addcart').modal();

							setTimeout(function () {
								onlyDigit()
							}, 100);
						});
					}, 100);
				}, 100);

			}
		},
		error: function(xhr, ajaxOptions, thrownError) {
			alert(thrownError + "/r/n" + xhr.statusText + "/r/n" + xhr.responseText);
		}
	});




});
/*****************************************************************/
// Cart add remove functions
var cart = {
	'add': function(product_id, quantity) {
		$.ajax({
			url: 'index.php?route=checkout/cart/add',
			type: 'post',
			data: 'product_id=' + product_id + '&quantity=' + (typeof(quantity) != 'undefined' ? quantity : 1),
			dataType: 'json',
			beforeSend: function() {
				//$('#cart > button').button('loading');
			},
			complete: function() {
				$('.cart > button').button('reset');
			},
			success: function(json) {
				$('.alert, .text-danger').remove();


				if (json['redirect']) {
					location = json['redirect'];
				}

				if (json['success']) {
					// Need to set timeout otherwise it wont update the total
					setTimeout(function () {
						$('.cart > button').html('<i class="icon icon-cart ani"></i><span class="cart-total">' + json['total'] + '</span>');
						$( "#modal_container_addcart #cart_popup_product_list > ul" ).load( "index.php?route=common/cart/info ul li", function() {
							$('#modal_container_addcart').modal();
						});
					}, 100);
				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	},
	'update': function(key, quantity, product_id) {
		$.ajax({
			url: 'index.php?route=checkout/cart/edit',
			type: 'post',
			//data: 'key=' + key + '&quantity=' + (typeof(quantity) != 'undefined' ? quantity : 1),
			data:  'quantity['+key+']=' + quantity + '&product_id=' + product_id,
			dataType: 'json',

			beforeSend: function() {
				//$('#cart > button').button('loading');
			},
			complete: function() {
				$('.cart > button').button('reset');
			},

			success: function(json) {

				// Need to set timeout otherwise it wont update the total
				setTimeout(function () {
					$('.cart > button').html('<i class="icon icon-cart ani"></i><span class="cart-total">' + json['total'] + '</span>');
					$("#modal_container_addcart #cart_popup_product_list > ul" ).load( "index.php?route=common/cart/info ul li");
				}, 100);

				if (getURLVar('route') == 'checkout/cart' || getURLVar('route') == 'checkout/checkout') {
					location = 'index.php?route=checkout/cart';
				} else {
					$('.cart .cart__content > ul').load('index.php?route=common/cart/info ul li');
				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	},
	'remove': function(key) {
		$.ajax({
			url: 'index.php?route=checkout/cart/remove',
			type: 'post',
			data: 'key=' + key,
			dataType: 'json',
			beforeSend: function() {
				$('.cart > button').button('loading');
			},
			complete: function() {
				$('.cart > button').button('reset');
			},
			success: function(json) {
				// Need to set timeout otherwise it wont update the total
				setTimeout(function () {
					$('.cart > button').html('<i class="icon icon-cart ani"></i><span class="cart-total">' + json['total'] + '</span>');

					$("#modal_container_addcart #cart_popup_product_list > ul" ).load( "index.php?route=common/cart/info ul li");

				}, 100);


				currenturl = window.location.href;
				var urlsplit = currenturl.split("/");
				urlsplit.splice(-1,1)
				var finalvar = urlsplit.pop();
				console.log(finalvar);

				if (getURLVar('route') == 'checkout/cart' || getURLVar('route') == 'checkout/checkout' || finalvar == 'cart') {
					console.log(getURLVar('route'));
					location = 'index.php?route=checkout/cart';
				} else {
					console.log(getURLVar('route'));
					$('.cart .cart__content > ul').load('index.php?route=common/cart/info ul li');

				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	}
}

var voucher = {
	'add': function() {

	},
	'remove': function(key) {
		$.ajax({
			url: 'index.php?route=checkout/cart/remove',
			type: 'post',
			data: 'key=' + key,
			dataType: 'json',
			beforeSend: function() {
				//$('#cart > button').button('loading');
			},
			complete: function() {
				$('.cart > button').button('reset');
			},
			success: function(json) {
				// Need to set timeout otherwise it wont update the total
				setTimeout(function () {
					$('.cart > button').html('<i class="icon icon-icon_cart ani"></i><span class="cart-total">' + json['total'] + '</span>');
				}, 100);

				if (getURLVar('route') == 'checkout/cart' || getURLVar('route') == 'checkout/checkout') {
					location = 'index.php?route=checkout/cart';
				} else {
					$('.cart > ul').load('index.php?route=common/cart/info ul li');
				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	}
}


var wishlist = {
	'add': function(product_id) {
		$.ajax({
			url: 'index.php?route=account/wishlist/add',
			type: 'post',
			data: 'product_id=' + product_id,
			dataType: 'json',
			success: function(json) {
				$('.alert').remove();

				if (json['redirect']) {
					location = json['redirect'];
				}

				if (json['success']) {

					var list, index;
					list = document.getElementsByClassName('wishlist-'+product_id);
					for (index = 0; index < list.length; ++index) {
						list[index].setAttribute('disabled', 'disabled');
						var product = list[index];
						var product_name = $(product).data("name");
						$('#modal_container_addwishlist .modal-header .title').text(product_name);
						$('.wishlist-total-counter').text(json['total']);
						$('.wishlist-total-counter').addClass('active');
					}

					$('#modal_container_addwishlist').modal();

					setTimeout(function () {
						$('#modal_container_addwishlist').modal('hide')
					}, 7000);

				}


			},
			error: function(xhr, ajaxOptions, thrownError) {
				alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	},
	'remove': function() {

	}
}

var compare = {
	'add': function(product_id) {
		$.ajax({
			url: 'index.php?route=product/compare/add',
			type: 'post',
			data: 'product_id=' + product_id,
			dataType: 'json',
			success: function(json) {
				$('.alert').remove();

				if (json['success']) {

					var list, index;

					list = document.getElementsByClassName('compare-'+product_id);
					for (index = 0; index < list.length; ++index) {
						list[index].setAttribute('disabled', 'disabled');
						var product = list[index];
						var product_name = $(product).parents('.product').find('.name a').html();
						$('#modal_container_addcompare .modal-header .title').text(product_name);
						$('.compare-total-counter').text(json['total']);
					}

					$('#modal_container_addcompare').modal();

					setTimeout(function () {
						//$('#modal_container_addcompare').modal('hide')
					}, 7000);

				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	},
	'remove': function() {

	}
}


/* Agree to Terms */
$(document).delegate('.agree', 'click', function(e) {
	e.preventDefault();

	$('#modal-agree').remove();

	var element = this;

	$.ajax({
		url: $(element).attr('href'),
		type: 'get',
		dataType: 'html',
		success: function(data) {
			html  = '<div id="modal-agree" class="modal">';
			html += '  <div class="modal-dialog modal-dialog-centered">';
			html += '    <div class="modal-content">';
			html += '      <div class="modal-header">';
			html += '        <button type="button" class="close" data-dismiss="modal" aria-label="Close">' +
				'                    <span aria-hidden="true" class="icon icon-icon_close_buble_mobile"></span>' +
				'                </button>';
			html += '        <h3 class="modal-title">' + $(element).text() + '</h3>';
			html += '      </div>';
			html += '      <div class="modal-body">' + data + '</div>';
			html += '    </div';
			html += '  </div>';
			html += '</div>';

			$('body').append(html);

			$('#modal-agree').modal('show');
		}
	});
});

// Autocomplete */
(function($) {
	$.fn.autocomplete = function(option) {
		return this.each(function() {
			this.timer = null;
			this.items = new Array();

			$.extend(this, option);

			$(this).attr('autocomplete', 'off');

			// Focus
			$(this).on('focus', function() {
				this.request();
			});

			// Blur
			$(this).on('blur', function() {
				setTimeout(function(object) {
					object.hide();
				}, 200, this);
			});

			// Keydown
			$(this).on('keydown', function(event) {
				switch(event.keyCode) {
					case 27: // escape
						this.hide();
						break;
					default:
						this.request();
						break;
				}
			});

			// Click
			this.click = function(event) {
				event.preventDefault();

				value = $(event.target).parent().attr('data-value');

				if (value && this.items[value]) {
					this.select(this.items[value]);
				}
			}

			// Show
			this.show = function() {
				var pos = $(this).position();

				$(this).siblings('ul.dropdown-menu').css({
					top: pos.top + $(this).outerHeight(),
					left: pos.left
				});

				$(this).siblings('ul.dropdown-menu').show();
			}

			// Hide
			this.hide = function() {
				$(this).siblings('ul.dropdown-menu').hide();
			}

			// Request
			this.request = function() {
				clearTimeout(this.timer);

				this.timer = setTimeout(function(object) {
					object.source($(object).val(), $.proxy(object.response, object));
				}, 200, this);
			}

			// Response
			this.response = function(json) {
				html = '';

				if (json.length) {
					for (i = 0; i < json.length; i++) {
						this.items[json[i]['value']] = json[i];
					}

					for (i = 0; i < json.length; i++) {
						if (!json[i]['category']) {
							html += '<li data-value="' + json[i]['value'] + '"><a href="#">' + json[i]['label'] + '</a></li>';
						}
					}

					// Get all the ones with a categories
					var category = new Array();

					for (i = 0; i < json.length; i++) {
						if (json[i]['category']) {
							if (!category[json[i]['category']]) {
								category[json[i]['category']] = new Array();
								category[json[i]['category']]['name'] = json[i]['category'];
								category[json[i]['category']]['item'] = new Array();
							}

							category[json[i]['category']]['item'].push(json[i]);
						}
					}

					for (i in category) {
						html += '<li class="dropdown-header">' + category[i]['name'] + '</li>';

						for (j = 0; j < category[i]['item'].length; j++) {
							html += '<li data-value="' + category[i]['item'][j]['value'] + '"><a href="#">&nbsp;&nbsp;&nbsp;' + category[i]['item'][j]['label'] + '</a></li>';
						}
					}
				}

				if (html) {
					this.show();
				} else {
					this.hide();
				}

				$(this).siblings('ul.dropdown-menu').html(html);
			}

			$(this).after('<ul class="dropdown-menu"></ul>');
			$(this).siblings('ul.dropdown-menu').delegate('a', 'click', $.proxy(this.click, this));

		});
	}
})(window.jQuery);
