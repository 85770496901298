function cong_feedback() {
    let text_cong_title = $('#feedback_form_cong_title').html();
    let text_cong_message = $('#feedback_form_cong_message').html();

    $('#modal_container_success').modal('hide');
    $('#modal_container_success .modal-header .title').html(text_cong_title);
    $('#modal_container_success .modal-body').html(text_cong_message);
    $('#modal_container_success').modal();

    setTimeout(function() {
        $("#feedback_form .reset_input").val('');
    }, 2000);

    $('#feedback_form').ajaxSubmit();

    return true;
}


$(document).ready(function() {
    $("#feedback_form .tel").mask("+38 (999) 999 99 99");

    $("#feedback_form").validate({

        submitHandler: function() {
            cong_feedback();
        },

        rules: {
            name: {
                required: true
            },
            email: {
                required: true,
                email: true
            },
            tel: {
                required: true,
                //digits: true
            },
            message:{
                required: true,
            },

        },
        messages: {
            name: {
                required: "<span class='lang ru'>Введите имя!</span><span class='lang ua'>Введіть ім`я!</span>"
            },
            email: {
                required: "<span class='lang ru'>Введите email!</span><span class='lang ua'>Введіть email!</span>",
                email: "<span class='lang ru'>Некорректний email!</span><span class='lang ua'>Некоректний email!</span>",
            },
            tel: {
                required: "<span class='lang ru'>Введите номер телефона!</span><span class='lang ua'>Введіть номер телефону!</span>"
            },
            message: {
                required: "<span class='lang ru'>Введите текст сообщения!</span><span class='lang ua'>Введіть текст повідомлення!</span>"
            },
        },
    });
});
