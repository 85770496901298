function showmorebutton() {
    if ($('.pagination_container .pagination li:last-child a').hasClass('has_link')) {
		var more_button = $('.more_button_text').text();
        $('.more_button_container').html('<button onclick="showmore()" id="showmore" class="reset_button button buy_button_white" id="more_products"><span class="icon icon-refresh"></span><span class="text">' + more_button + '</span></button>');

    }
}




function showmore() {

    var $next = $('.pagination_container .pagination li:last-child a.has_link');
	
	
    if ($next.length == 0) {
        return;
    }
    $.get($next.attr('href'), function (data) {
        $data = $(data);

        //console.log($data);

        var $container = $('#mfilter-content-container .products_row');
		//$container.append($data.find('.products_row .item'));
        $container.append($data.find('.products_row .product-layout'));
		
		//products_counter();
        $('.bottom-wrapper').remove();
        $("#mfilter-content-container").append($(data).find('.bottom-wrapper'));

        showmorebutton();

        /*if (localStorage.getItem('display') == 'list-view') {
            $('#list-view').trigger('click');
        }else{
            $('#grid-view').trigger('click');
        };*/
		
        //$('.pagination').html($data.find('.pagination > *'));

        //if ($('.pagination li.active').next('li').find('a.digit').length == 0) {
        //    $('#showmore').hide();
        //}

        //popup_ext
       /*setTimeout(function(){
            add_class();
            p_array();
        }, 500);*/
		
		/*$data.filter('script').each(function () {
            if ((this.text || this.textContent || this.innerHTML).indexOf("document.write") >= 0) {
                return;
            }
            $.globalEval(this.text || this.textContent || this.innerHTML || '');
        });*/
        //$('html, body').animate({ scrollTop: $container.offset().top - 10 }, 'slow');

    }, "html");
    return false;
}
